import cx from "classnames";
import React, { FC } from "react";

import { Newsletter } from "../components/newsletter";
import SEO from "../components/seo";

import * as global from "../styles/global.module.scss";
import * as quizStyle from "../styles/shared/quiz.module.scss";

interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

const IndexPage: FC<Props> = (props: Props) => {
  const { location } = props;
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={quizStyle.introContainer}>
          <h2 className={cx(quizStyle.title, global.bigTitle, global.title)}>
            Je fais une pause pour le moment ! <br />Je profite de la vie et du soleil, on se capte plus tard (peut être).
          </h2>
        </div>
        {/* <div className={quizStyle.questionContainer}>
          <h3 className={cx(quizStyle.title, global.title)}>
            Comment puis-je vous aider&nbsp;?
          </h3>
          <ul className={quizStyle.questionCardList}>
            <li>
              <div className={quizStyle.questionCard}>
                <h4 className={quizStyle.cardTitle}>
                  Vous savez ce <br /> que vous voulez&nbsp;?
                </h4>
                <p className={cx(quizStyle.cardText, global.text)}>
                  "J'ai un vêtement en tête et je sais plus ou moins ce que je
                  veux."
                </p>
                <div className={quizStyle.cardCtaContainer}>
                  <NavCta
                    to={routes.app.parcours.demande.guidePerso.path}
                    label="Cliquez-ici"
                    onClick={() => {
                      sendGA("click", {
                        event_label: "home - cliquez-ici - demande",
                      });
                    }}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={quizStyle.questionCard}>
                <h4 className={quizStyle.cardTitle}>
                  Vous n'êtes pas <br /> super au clair ?
                </h4>
                <p className={cx(quizStyle.cardText, global.text)}>
                  "Je ne sais pas trop ce que je veux, je n'ai rien de précis en
                  tête."
                </p>
                <div className={quizStyle.cardCtaContainer}>
                  <NavCta
                    to={routes.app.parcours.decouverte.step1.path}
                    label="Cliquez-ici"
                    onClick={() => {
                      sendGA("click", {
                        event_label: "home - cliquez-ici - decouverte",
                      });
                    }}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div> */}
        {/* <Newsletter /> */}
      </div>
    </>
  );
};

export default IndexPage;
